import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Form from './Form'

const EventForm = (props) => {
  // eslint-disable-next-line no-restricted-globals
  // eslint-disable-next-line react/prop-types
  const { id, nonce } = props
  const [selectedEvent] = useState(id)
  const [event, setEvent] = useState(null)
  const [event1, setEvent1] = useState(null)
  const [event2, setEvent2] = useState(null)
  const [event3, setEvent3] = useState(null)

  useEffect(() => {
    async function loadEvents() {
      const url = '/wp-json/api/event/'
      Promise.all([
        fetch(`${url}${selectedEvent}`, { method: 'get' }),
        fetch(`${url}374`, { method: 'get' }),
        fetch(`${url}377`, { method: 'get' }),
        fetch(`${url}515`, { method: 'get' })
      ]).then(([response, response1, response2, response3]) => {
        Promise.all([response.json(), response1.json(), response2.json(), response3.json()]).then(
          ([newEvent, newEvent1, newEvent2, newEvent3]) => {
            setEvent(newEvent)
            setEvent1(newEvent1)
            setEvent2(newEvent2)
            setEvent3(newEvent3)
          }
        )
      })
    }
    if (selectedEvent) {
      loadEvents()
    }
  }, [])

  return (
    <div className='element element__event-form'>
      {event !== null && event1 !== null && event2 !== null && event3 !== null && (
        <>
          <div className='event-form__intend-placeholder' />
          <div className='event-form__container'>
            <div className='event-form__intend-placeholder' />
            <div className='event-form__container'>
              <Form
                event={event}
                nonce={nonce}
                selectedEvent={selectedEvent}
                event1={event1}
                event2={event2}
                event3={event3}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const domContainer = document.getElementById('eventForm')
if (domContainer) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  ReactDOM.render(<EventForm {...domContainer.dataset} />, domContainer)
}
