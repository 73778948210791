import '../styles/main.scss'

import './components/EventForm'

import hoverintent from 'hoverintent'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

if (module.hot) module.hot.accept()

document.addEventListener('DOMContentLoaded', () => {
  const imgParent = document.querySelector('.image-animated')
  let img
  if (imgParent) {
    img = imgParent.querySelector('img')

    img.addEventListener(
      'animationend',
      // eslint-disable-next-line no-use-before-define
      transitionEndCallback
    )
  }

  function transitionEndCallback() {
    img.removeEventListener('animationend', transitionEndCallback)
    imgParent.classList.remove('image-animated')
    imgParent.classList.add('image-animated-finished')
  }

  // Detect touch device
  //----------------------------------------------------------
  const isTouch = 'ontouchstart' in document.documentElement !== false

  // Header dropdown menus
  //----------------------------------------------------------
  const menuElements = [...document.querySelectorAll('.header-menu > .menu-item-has-children')]
  const menuLinkElements = [
    ...document.querySelectorAll('.header-menu  .menu-item-has-children > a')
  ]

  const subMenuElements = [
    ...document.querySelectorAll('.menu-item-has-children  .menu-item-has-children ')
  ]

  console.log(subMenuElements)

  const openSubmenu = (element) => {
    element.classList.add('submenu-is-open')
  }
  const closeSubmenu = (element) => {
    element.classList.remove('submenu-is-open')
  }

  // Regular devices
  const opts = {
    timeout: 250,
    interval: 50
  }
  const opts1 = {
    timeout: 50,
    interval: 10
  }

  if (!isTouch) {
    document.body.classList.add('no-touch')
    menuElements.forEach((element) => {
      hoverintent(
        element,
        () => {
          openSubmenu(element)
        },
        () => {
          closeSubmenu(element)
        }
      ).options(opts)
    })
    subMenuElements.forEach((element) => {
      hoverintent(
        element,
        () => {
          openSubmenu(element)
        },
        () => {
          closeSubmenu(element)
        }
      ).options(opts1)
    })
    // Prevent links on parents
    menuLinkElements.forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault()
      })
    })
  }

  // Touch devices
  if (isTouch) {
    document.body.classList.add('touch')
    menuLinkElements.forEach((element) => {
      element.addEventListener('click', (event) => {
        menuElements.forEach((node) => {
          node.classList.remove('submenu-is-open')
        })
        element.parentNode.classList.add('submenu-is-open')
        event.preventDefault()
      })
    })
  }

  // Mobile menu
  //----------------------------------------------------------
  const mobileMenu = document.getElementById('mobile-menu')
  const openMobileMenuButton = document.getElementById('open-mobile-menu')
  const closeMobileMenuButton = document.getElementById('close-mobile-menu')
  openMobileMenuButton.addEventListener(
    'touchstart',
    (event) => {
      mobileMenu.classList.add('mobile-menu-is-open')
      disableBodyScroll(mobileMenu)
      event.preventDefault()
    },
    { passive: false }
  )
  closeMobileMenuButton.addEventListener(
    'touchstart',
    (event) => {
      mobileMenu.classList.remove('mobile-menu-is-open')
      enableBodyScroll(mobileMenu)
      event.preventDefault()
    },
    { passive: false }
  )

  // Mobile dropdown menu
  //----------------------------------------------------------
  const mobileDropdownItems = [
    ...document.querySelectorAll('.mobile-menu .menu-item-has-children > a')
  ]
  const handleMobileDropdown = (event) => {
    const listItem = event.currentTarget.parentNode
    if (listItem.classList.contains('submenu-is-open')) {
      listItem.classList.remove('submenu-is-open')
    } else {
      mobileDropdownItems.forEach((item) => {
        item.parentNode.classList.remove('submenu-is-open')
      })
      listItem.classList.add('submenu-is-open')
    }
    event.preventDefault()
  }
  mobileDropdownItems.forEach((link) => {
    link.addEventListener('touchstart', handleMobileDropdown, { passive: false })
  })
})
