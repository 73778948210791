/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'

// eslint-disable-next-line react/prop-types
const Form = ({ event, selectedEvent, nonce, event1, event2, event3 }) => {
  const [firstname, setFirstname] = useState('')
  const [surname, setSurename] = useState('')
  const [mail, setMail] = useState('')
  const [tel, setTel] = useState('')
  const [persons, setPersons] = useState('')
  const [viewState, setViewState] = useState('registration')
  const [selectedTrip, setSelectedTrip] = useState('0')

  function handleInputChange(e, callbackFn) {
    callbackFn(e.target.value)
  }

  async function handleSubmit() {
    setViewState('loading')
    const reservationData = new FormData()
    reservationData.append('action', 'handle_booking') // WP Ajax action
    reservationData.append('nonce', nonce) // WP Nonce
    reservationData.append('firstname', firstname)
    reservationData.append('surname', surname)
    reservationData.append('mail', mail)
    reservationData.append('tel', tel)
    reservationData.append('persons', persons)
    reservationData.append('event', JSON.stringify(event))
    reservationData.append('trip', selectedTrip)

    const url = '/wp-json/api/event/'

    if (event.type === 'Eröffnung') {
      if (selectedTrip === '0') {
        const response1 = await fetch(`${url}send`, {
          method: 'POST',
          body: reservationData
        })
        // if this is successful send registartion mail
        if (response1.ok) {
          const result = await response1.json()
          console.log(result)
          if (result === 111) {
            setViewState('finished')
          }
        }
      }

      if (selectedTrip === '1') {
        const response = await fetch(`${url}reservations/374/${persons}`, {
          method: 'PUT'
        })

        if (response.ok) {
          const response1 = await fetch(`${url}send`, {
            method: 'POST',
            body: reservationData
          })
          // if this is successful send registartion mail
          if (response1.ok) {
            const result = await response1.json()
            console.log(result)
            if (result === 111) {
              setViewState('finished')
            }
          }
        } else {
          console.log(response)
        }
      }

      if (selectedTrip === '2') {
        const response = await fetch(`${url}reservations/377/${persons}`, {
          method: 'PUT'
        })
        if (response.ok) {
          const response1 = await fetch(`${url}send`, {
            method: 'POST',
            body: reservationData
          })
          // if this is successful send registartion mail
          if (response1.ok) {
            const result = await response1.json()
            console.log(result)
            if (result === 111) {
              setViewState('finished')
            }
          } else {
            console.log(response)
          }
        }
      }

      if (selectedTrip === '3') {
        const response = await fetch(`${url}reservations/515/${persons}`, {
          method: 'PUT'
        })
        if (response.ok) {
          const response1 = await fetch(`${url}send`, {
            method: 'POST',
            body: reservationData
          })
          // if this is successful send registartion mail
          if (response1.ok) {
            const result = await response1.json()
            console.log(result)
            if (result === 111) {
              setViewState('finished')
            }
          } else {
            console.log(response)
          }
        }
      }
    } else {
      const response = await fetch(`${url}reservations/${selectedEvent}/${persons}`, {
        method: 'PUT'
      })
      // Reduce nuber of personen over react api
      if (response.ok) {
        const response1 = await fetch(`${url}send`, {
          method: 'POST',
          body: reservationData
        })
        // if this is successful send registartion mail
        if (response1.ok) {
          const result = await response1.json()
          if (result === 111) {
            setViewState('finished')
          }
        }
      }
    }
  }

  const ReservationFree = (
    <>
      <h4>Anmeldung</h4>

      {event.type !== 'Eröffnung' && !event.hideEventLimit && (
        <div className='event-form__row'>
          <div className='event-form__small-title'>Limitierte Platzzahl:</div>
          <div className='event-form__small-content'>
            {event.numOfSeats}/{event.limit_max} verfügbar
          </div>
        </div>
      )}
      <form
        className='event-form__form'
        onSubmit={(e) => {
          handleSubmit()
          e.preventDefault()
        }}>
        <div className='event-form__two'>
          <input
            required
            type='text'
            placeholder='Vorname'
            value={firstname}
            onChange={(e) => handleInputChange(e, setFirstname)}
          />
          <input
            required
            type='text'
            placeholder='Nachname'
            value={surname}
            onChange={(e) => handleInputChange(e, setSurename)}
          />
        </div>
        <div className='event-form__two'>
          <input
            required
            type='email'
            placeholder='E-Mail'
            value={mail}
            onChange={(e) => handleInputChange(e, setMail)}
          />
          <input
            required
            type='tel'
            placeholder='Tel. Nr. / Mobile'
            value={tel}
            onChange={(e) => handleInputChange(e, setTel)}
          />
        </div>
        {event.type === 'Eröffnung' && (
          <>
            {(event1.numOfSeats > 0 || event2.numOfSeats > 0 || event3.numOfSeats > 0) && (
              <div className='event-form__row new-segment'>
                Falls Sie an einer Führung am Eröffnungstag teilnehmen möchten, kreuzen Sie eine an.
              </div>
            )}
            {event1.numOfSeats <= 0 && event2.numOfSeats <= 0 && event3.numOfSeats && (
              <div className='event-form__row new-segment'>
                Es kann nur noch am Eröffnungsevent um 14:00Uhr teilgenommen werden.
              </div>
            )}
            {event1.numOfSeats > 0 && (
              <div className='event-form__row'>
                <input
                  className='event_form__input_checkbox'
                  type='radio'
                  name='trip'
                  value='1'
                  onChange={(e) => handleInputChange(e, setSelectedTrip)}
                  required
                />
                <div className='event_form__input_title'>13:00Uhr Führung</div>
                <div className='event_form__input-content'>
                  {event1.numOfSeats}/{event1.limit_max} Plätzen verfügbar
                </div>
              </div>
            )}
            {event2.numOfSeats > 0 && (
              <div className='event-form__row'>
                <input
                  className='event_form__input_checkbox'
                  type='radio'
                  name='trip'
                  value='2'
                  onChange={(e) => handleInputChange(e, setSelectedTrip)}
                />
                <div className='event_form__input_title'>15:00Uhr Führung</div>
                <div className='event_form__input-content'>
                  {event2.numOfSeats}/{event2.limit_max} Plätzen verfügbar
                </div>
              </div>
            )}
            {event3.numOfSeats > 0 && true && (
              <div className='event-form__row'>
                <input
                  className='event_form__input_checkbox'
                  type='radio'
                  name='trip'
                  value='3'
                  onChange={(e) => handleInputChange(e, setSelectedTrip)}
                />
                <div className='event_form__input_title'>16:00Uhr Führung</div>
                <div className='event_form__input-content'>
                  {event3.numOfSeats}/{event3.limit_max} Plätzen verfügbar
                </div>
              </div>
            )}
            <div className='event-form__row'>
              <input
                className='event_form__input_checkbox'
                type='radio'
                name='trip'
                value='0'
                onChange={(e) => handleInputChange(e, setSelectedTrip)}
              />
              An keiner Führung teilnehmen (nur Eröffnung 14:00Uhr)
            </div>
          </>
        )}

        <div className='event-form__row new-segment'>Wählen Sie bitte die Anzahl Personen aus</div>
        {((selectedTrip === '1' && event1.numOfSeats >= 1) ||
          (selectedTrip === '2' && event2.numOfSeats >= 1) ||
          (selectedTrip === '3' && event3.numOfSeats >= 1) ||
          selectedTrip === '0') && (
            <div className='event-form__row'>
              <input
                className='event_form__input_checkbox'
                type='radio'
                name='choice'
                value='1'
                onChange={(e) => handleInputChange(e, setPersons)}
                required
              />{' '}
              1 Person
            </div>
          )}
        {((selectedTrip === '1' && event1.numOfSeats >= 2) ||
          (selectedTrip === '2' && event2.numOfSeats >= 2) ||
          (selectedTrip === '3' && event3.numOfSeats >= 2) ||
          selectedTrip === '0') && (
            <div className='event-form__row'>
              <input
                className='event_form__input_checkbox'
                type='radio'
                name='choice'
                value='2'
                onChange={(e) => handleInputChange(e, setPersons)}
              />{' '}
              2 Personen
            </div>
          )}

        <div className='event-form__row new-segment'>
          <input type='submit' value='Anmeldung absenden' />
        </div>
        <div className='event-form__after-text'>
          Das sollten Sie noch wissen: Nachdem Sie die Anmeldung abgeschickt haben, erhalten Sie
          eine automatische Mail mit der Bestätigung. Bitte den Spamordner prüfen, wenn Sie keine
          Mail im Posteingang vorfinden. Danke.
        </div>
      </form>
    </>
  )
  console.log(event)
  const ReservationFreeOnePerson = (
    <>
      <h4>Anmeldung</h4>
      {event.type !== 'Eröffnung' && (
        <div className='event-form__row'>
          <div className='event-form__small-title'>Limitierte Platzzahl:</div>
          <div className='event-form__small-content'>
            {event.numOfSeats}/{event.limit_max} verfügbar
          </div>
        </div>
      )}
      <form
        className='event-form__form'
        onSubmit={(e) => {
          handleSubmit()
          e.preventDefault()
        }}>
        <div className='event-form__two'>
          <input
            required
            type='text'
            placeholder='Vorname'
            value={firstname}
            onChange={(e) => handleInputChange(e, setFirstname)}
          />
          <input
            required
            type='text'
            placeholder='Nachname'
            value={surname}
            onChange={(e) => handleInputChange(e, setSurename)}
          />
        </div>
        <div className='event-form__two'>
          <input
            required
            type='email'
            placeholder='E-Mail'
            value={mail}
            onChange={(e) => handleInputChange(e, setMail)}
          />
          <input
            required
            type='tel'
            placeholder='Tel. Nr. / Mobile'
            value={tel}
            onChange={(e) => handleInputChange(e, setTel)}
          />
        </div>

        <div className='event-form__row new-segment'>Wählen Sie bitte die Anzahl Personen aus</div>
        <div className='event-form__row'>
          <input
            className='event_form__input_checkbox'
            type='radio'
            name='choice'
            value='1'
            onChange={(e) => handleInputChange(e, setPersons)}
            required
          />{' '}
          1 Person
        </div>
        <div className='event-form__row new-segment'>
          <input type='submit' value='Anmeldung absenden' />
        </div>
        <div className='event-form__after-text'>
          Das sollten Sie noch wissen: Nachdem Sie die Anmeldung abgeschickt haben, erhalten Sie
          eine automatische Mail mit der Bestätigung. Bitte den Spamordner prüfen, wenn Sie keine
          Mail im Posteingang vorfinden. Danke.
        </div>
      </form>
    </>
  )

  const ReservationFull = (
    <div className='event-form__error'>Diese Veranstaltung ist schon ausgebucht.</div>
  )
  const DateUntil = <div className='event-form__error'>Das Anmeldedatum ist abgelaufen.</div>
  const onWork = <div className='event-form__error'>Wird verarbeitet.</div>
  const Finished = (
    <div className='event-form__error'>
      Vielen Dank, Ihre Anmeldung war erfolgreich! Die Bestätigung wurde Ihnen per E-Mail an {mail}{' '}
      gesendet.
    </div>
  )
  return (
    <>
      {viewState === 'registration' && event.isRegistraionRequired && (
        <>
          {new Date() > new Date(event.register_until) && DateUntil}
          {event.numOfSeats >= 2 && ReservationFree}
          {event.numOfSeats === '1' && ReservationFreeOnePerson}
          {event.numOfSeats <= 0 && ReservationFull}
        </>
      )}
      {viewState === 'loading' && onWork}
      {viewState === 'finished' && Finished}
    </>
  )
}

export default Form
